import React, {useState, useRef, useEffect} from "react"
import parse from 'html-react-parser'
import Image from '../page/ResuableImageWrapper';
import VisibilitySensor from 'react-visibility-sensor';

const CareersSection3 = ({ content }) => {
  
  
  const [isInview, setIsInview] = useState(false);

  function inviewOnChange (isVisible) {
    setIsInview(isVisible)
  }

  const targetRef = useRef()
  const newPositionRef = useRef()
  const originalPositionRef = useRef()

  const repositionElement = () => {
      if(typeof window !== undefined){
          const target = targetRef.current
          const newPosition = newPositionRef.current
          const originalPosition = originalPositionRef.current

          if(window.innerWidth < 1024 ){
              newPosition.parentNode.insertBefore(target, newPosition)
          }else{
              originalPosition.parentNode.insertBefore(target, originalPosition)
          }
      }
  }

  useEffect(()=> {
      repositionElement()

      if(typeof window !== undefined){
          window.addEventListener('resize', repositionElement)    
      }
     return () => {
          window.removeEventListener('resize', repositionElement)    
      }
  },[])


  return (
    <VisibilitySensor partialVisibility={true} offset={{bottom:120}} active={!isInview}  onChange={inviewOnChange}>
      {({isVisible}) =>
        <section className={`section-4  ${isInview ? 'is_inview' : ''}`}>
          <div className="wrapper-large clearfix">
            <div className="sticky-side">
              <span className="originalPostion"><span ref={originalPositionRef}></span></span>
              <span ref={targetRef}>
                <h2>{content.stickyHeading}</h2>
                <div className="text">{parse(content.stickyText)}</div>
                <div className="image">
                  <Image image={content?.stickyImage?.localFile?.childImageSharp?.fluid} />
                </div>
              </span>
            </div>
            
            <div className="scrolling-points">
              <div className="non-sticky-side ">
                <article className="orange">
                <span className="newPostion"><span ref={newPositionRef}></span></span>
                </article>
              </div>
              
              <ul className="points">
                {content.scrollingSections.map((section) => {
                  return(
                    <li className="point">
                      <article className={section.colour}>
                        <h3>{section.heading}</h3>
                        <div className="text">{parse(section.text)}</div>
                      </article>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </section>
      }
    </VisibilitySensor>  
  )
}


export default CareersSection3
