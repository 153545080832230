import React from "react"
import CareersIntroComponent from './CareersIntroComponent'

const CareersIntro = ({ content }) => {
  
  const sections = content.introSections;

  

  return (
        <section className={`intro_section-wrap clearfix`}>
          <div className="wrapper-large clearfix">
            {
              sections.map((section, index) => {    
                return(
                  <CareersIntroComponent section={section} index={index} />   
                )
              })
            }
          </div>
        </section>
  )
}


export default CareersIntro
