import React, {useState} from "react"
import parse from 'html-react-parser'
import JobsForm from './JobsForm';
import VisibilitySensor from 'react-visibility-sensor';

const CareersSection3 = ({ content, hasJobs }) => {
  

  const [isInview, setIsInview] = useState(false);

  function inviewOnChange (isVisible) {
    setIsInview(isVisible)
  }


  return (

    <VisibilitySensor partialVisibility={true} offset={{bottom:120}} active={!isInview}  onChange={inviewOnChange}>
      {({isVisible}) =>
        <section className={`section-7 ${isInview ? 'is_inview' : ''} ${hasJobs === true ? '' : 'margin-top' } `}>
          <div className="wrapper">
            <h2>{hasJobs === true ? content.section7Heading : content.section6Heading}</h2>
            <div className="text">{hasJobs === true ? parse(content.section7Text) : parse(content.section7TextAlternate)}</div>
            <JobsForm />
          </div>
        </section> 
      }
    </VisibilitySensor>  
  )
}


export default CareersSection3
