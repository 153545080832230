import React, {useState} from "react"
import parse from 'html-react-parser'
import LottieWrap from '../lottie/lottieWrap'
import VisibilitySensor from 'react-visibility-sensor';
import {Link} from 'gatsby';

const CareersSection3 = ({ content, jobs, hasJobs }) => {

  const [isInview, setIsInview] = useState(false);

  function inviewOnChange (isVisible) {
    setIsInview(isVisible)
  }


  return (
    hasJobs === true ?
    <VisibilitySensor partialVisibility={true} offset={{bottom:120}} active={!isInview}  onChange={inviewOnChange}>
      {({isVisible}) =>
       
        <section className={`section-6  ${isInview ? 'is_inview' : ''}`}>
          <div className="wrapper">
            <h2>{content.section6Heading}</h2>
    
            <div className="vacancies-wrapper">
              <ul className="the-vacancies"> 
                {
                  jobs.nodes.map((job) => {
                    return(
                      <li 
                      id={job.careerCats.nodes[0].slug+"_wrap"} 
                      className={`vacancy clearfix ${job.careerCats.nodes[0].slug}`}
                      > 
                        <Link to={job.link}>
                          <div className="image-wrap-outer">
                            <div className="lottie_wrap"  data-target={job.careerCats.nodes[0].slug}>
                              <LottieWrap playOnload={true} animation={job.careerCats.nodes[0].slug+"_moreservices"}></LottieWrap> 
                            </div>
                          </div>
                          <div className="text-wrap">
                            <h3>{job.title}</h3>
                            <div className="application_details">
                              <span className="salary">Salary: {job.acf_jobs.salary} {job.acf_jobs.subjectToExperienceToggle === true ? ' DOE ' : ''}
                              </span>
                              <span className="deadline"> | Application Deadline: {job.acf_jobs.applicationDeadline}</span>
                            </div>
                            <div className="excerpt">{parse(job.excerpt)}</div>
                            <div className="more">Find out more</div>
                          </div>
                        </Link>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
          </div>
        </section>
      }
    </VisibilitySensor>
    : ""
 
    
  )
}


export default CareersSection3
