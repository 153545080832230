import React, { useEffect } from "react";
import CareersIntro from "../components/careers/CareersIntro";
import CareersSection2 from "../components/careers/CareersSection2";
import CareersSection3 from "../components/careers/CareersSection3";
import CareersSection4 from "../components/careers/CareersSection4";
import CareersSection5 from "../components/careers/CareersSection5";
import CareersSection6 from "../components/careers/CareersSection6";
import CareersSection7 from "../components/careers/CareersSection7";
import { SchemaLinksToAbsolute } from "../helpers/SchemaLinksToAbsolute";
import { graphql } from "gatsby";
import VideoTranscriptionBlock from "../../src/components/page/VideoTranscriptionBlock";
import Seo from "../components/seo";
import "../sass/pages/_careers-archive.scss";

const Careers = ({ data: { jobs, careerData } }) => {
  const content = careerData.acf_careers;

  useEffect(() => {
    if (typeof document !== "undefined") {
      document.querySelector("body").setAttribute("data-bg-color", "bg-white");
      document.querySelector("body").setAttribute("data-class", "careers-archive");
    }
  }, []);

  // const offsetVideo = () => {
  //   const videoHeight = document.querySelector(
  //     ".video-wrapper-outer-outer  .video-transcript-block"
  //   ).offsetHeight;

  //   const section_2 = document.querySelector(".section-2 ");
  //   const intro_section = document.querySelector(".intro_section-wrap");
  //   section_2.style.marginTop = -videoHeight / 2 - 1 + "px";
  //   // [intro_section.style.paddingBottom = videoHeight / 2 + "px";]

  //   console.log(videoHeight);
  // };

  // useEffect(() => {
  //   if (typeof document !== "undefined") {
  //     offsetVideo();

  //     window.addEventListener("resize", offsetVideo);
  //   }
  // }, []);

  return (
    <>
      <Seo
        title={careerData.seo.title}
        description={careerData.seo.metaDesc}
        date={careerData.date}
        slug={"/" + careerData.slug + "/"}
        robotsFollow={careerData.seo.metaRobotsNofollow}
        robotsIndex={careerData.seo.metaRobotsNoindex}
        canonicalUrl={careerData.seo.canonical}
      />
      {careerData.seo.schema.raw !== undefined ? (
        <SchemaLinksToAbsolute schemaRaw={careerData.seo.schema.raw} />
      ) : (
        ""
      )}
      <div className="careers-archive post-type-archive-careers">
        <CareersIntro content={content} />
        <div className="video-block">
          <div className="title">New starters at Edge45®</div>
          <div className="video-wrapper-outer-outer">
            <div className="video-wrapper-outer">
              <div className="video-wrapper">
                <iframe
                  src="https://player.vimeo.com/video/797293553?h=cfef0ca952&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                  title="New starters at Edge45&amp;reg;"></iframe>
              </div>
            </div>
            <VideoTranscriptionBlock
              text={`<p>Coming to a small agency is a great way for anyone to develop their career, whether it's of myself who has been in the industry for many years, to the beginners who come on as maybe a writer or an SEO executive.</p>
<p>I think you can expect to receive a lot of training and you're working with a lot of people that have been there and done that in a lot of other agencies. I think that's the real special thing about this place is you've got a real blend of people that are in different points in their career but they've got a lot of different things to bring to the table.</p>
<p>You're expected to work hard, you're expected to give it 100%, but there's also a lot of support behind that as well. So if you feel like there's something stopping you from achieving that, there's lots of people around and lots of opportunities to get the help that you need.</p>
<p>And straight away we look at stuff like, okay, where are your skills, where are your gaps?</p>
<p>You'll learn a lot, you've got flexibility here as well, you're trusted with your work, you're not micro-managed.</p>
<p>So at Edge, we may take somebody on to go in technical for example, but we'll also be developing them in different areas of the business so that they get a full idea really of what else there is to offer.</p>`}
            />
          </div>
        </div>
        <CareersSection2 content={content} />
        <CareersSection3 content={content} />
        <CareersSection4 content={content} />
        <CareersSection5 content={content} />
        <CareersSection6
          content={content}
          jobs={jobs}
          hasJobs={jobs.nodes.length > 0 ? true : false}
        />
        <CareersSection7 content={content} hasJobs={jobs.nodes.length > 0 ? true : false} />
      </div>
    </>
  );
};

export const query = graphql`
  {
    jobs: allWpCareer {
      nodes {
        title
        excerpt
        link
        acf_jobs {
          salary
          applicationDeadline
          subjectToExperienceToggle
        }
        careerCats {
          nodes {
            slug
          }
        }
      }
    }
    careerData: wpPage(slug: { eq: "careers" }) {
      id
      title
      date
      slug
      seo {
        title
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        canonical
        schema {
          raw
        }
      }
      acf_careers {
        introSections {
          heading
          image1 {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          image2 {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          image3 {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          text
        }
        scrollingSections {
          colour
          heading
          text
        }
        section2Sections {
          heading
          points {
            heading
            icon {
              altText
              localFile {
                publicURL
              }
            }
            text
          }
        }
        section3Heading
        section3Image {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        section3Text
        section5Heading
        section5Text
        section6Heading
        section7Heading
        section7Text
        section7TextAlternate
        stickyHeading
        stickyText
        section5Image {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        stickyImage {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

export default Careers;
