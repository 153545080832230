import React, {useState, useEffect} from "react"
import parse from 'html-react-parser'
import Image from '../page/ResuableImageWrapper';
import VisibilitySensor from 'react-visibility-sensor';
import { gsap } from "gsap";
import SplitText from "gsap/SplitText";
gsap.registerPlugin(SplitText);

const AboutIntroComponent = ({ section, index }) => {
  

  const [isInview, setIsInview] = useState(false);

  function inviewOnChange (isVisible) {
    setIsInview(isVisible)
  }


  useEffect(() => {
    if(typeof document !== 'undefined'){

      
      // let text = heroText.innerHTML;
        let headings = gsap.utils.toArray('.text-wrap h2 span');
        
		  	var heroText_split = new SplitText(headings, {type:"lines", linesClass:'lines'});
        
        heroText_split.lines.map(line => {
          let grandparent = line.parentNode.parentNode;
          let parent = line.parentNode;
          let originalHtml = line.innerHTML;
          let newHtml = `<div>${originalHtml}</div>`

          line.innerHTML = newHtml;

          grandparent.append(line);
          // Array.from(grandparent.querySelectorAll('.lines')).map(line => {
          //   setTimeout(function(){
          //     line.classList.add('is_inview')
          //   }.bind(this), 1);
              
          // })
          parent.style.display = 'none';

        })

    }
    
  
  }, [])


  return (
    <VisibilitySensor partialVisibility={true} offset={{bottom:120}} active={!isInview}  onChange={inviewOnChange}>
        {({isVisible}) =>
                  <article className={`intro_section ${isInview ? 'is_inview' : ''}`}>
                    
                    {index % 2 === 0 ? 
                      <div className="images">
                        <div className="image-1">
                          <Image image={section?.image1?.localFile?.childImageSharp?.fluid} /> 
                        </div>
                        <div className="image-2">
                          <Image image={section?.image2?.localFile?.childImageSharp?.fluid} /> 
                        </div>
                      </div>	
                    : " "
                    }
    
                    <div id="careers-intro--text" className="text-wrap">
                      {index+1 === 1 ? <h1 className="label" htmlFor="careers-intro--text">Careers</h1> : ""}
                      <h2><span>{section.heading}</span></h2>
                      <div className="divider"></div>
                      <div className="text">{parse(section.text)}</div>
                    </div>
                    
                    {index % 2 !== 0 ? 
                      <div className="images">
                        <div className="image-1">
                          <Image image={section?.image1?.localFile?.childImageSharp?.fluid} /> 
                        </div>
                        <div className="image-2">
                          <Image image={section?.image2?.localFile?.childImageSharp?.fluid} /> 
                        </div>
                        <div className="image-3">
                          <Image image={section?.image3?.localFile?.childImageSharp?.fluid} /> 
                        </div>
                      </div>	
                    : " "
                    }
                  </article>      
                
              
        }
      
    </VisibilitySensor>
     
  )
}


export default AboutIntroComponent
