import React, {useState} from "react"
import parse from 'html-react-parser'
import Image from '../page/ResuableImageWrapper';
import VisibilitySensor from 'react-visibility-sensor';


const CareersSection3 = ({ content }) => {
  
  
  const [isInview, setIsInview] = useState(false);

  function inviewOnChange (isVisible) {
    setIsInview(isVisible)
  }


  return (
    <VisibilitySensor partialVisibility={true} offset={{bottom:120}} active={!isInview}  onChange={inviewOnChange}>
      {({isVisible}) =>
          <section className={`section-3 ${isInview ? 'is_inview' : ''}`}>
            <div className="wrapper-large">
              <h2>{content.section3Heading}</h2>
              <div className="text">{parse(content.section3Text)}</div>
              <div className="image">
                <Image image={content?.section3Image?.localFile?.childImageSharp?.fluid} />
                {content?.section3Image?.localFile?.childImageSharp?.fluid ? '' : "image not found"}
              </div>
            </div>
          </section>
      }
    </VisibilitySensor>    
  )
}


export default CareersSection3
