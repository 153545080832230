import React, {useState} from "react"
import parse from 'html-react-parser'
import Image from '../page/ResuableImageWrapper';
import VisibilitySensor from 'react-visibility-sensor';

const CareersSection3 = ({ content }) => {


  const [isInview, setIsInview] = useState(false);

  function inviewOnChange (isVisible) {
    setIsInview(isVisible)
  }

  return (
    <VisibilitySensor partialVisibility={true} offset={{bottom:120}} active={!isInview}  onChange={inviewOnChange}>
      {({isVisible}) =>
        <section className={`section-5 ${isInview ? 'is_inview' : ''}`}>
          <div className="wrapper">
            <h3>{content.section5Heading}</h3>
            <div className="text">{parse(content.section5Text)}</div>
            <div className="image">
              <Image image={content?.section5Image?.localFile?.childImageSharp?.fluid} />
            </div>
          </div>
        </section>
      }
    </VisibilitySensor>
  
  )
}


export default CareersSection3
