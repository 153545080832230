import React, {useState} from "react"
import parse from 'html-react-parser'
import VisibilitySensor from 'react-visibility-sensor';

const CareersSection2 = ({ content }) => {
  
  const sections = content.section2Sections;
  
  const [isInview, setIsInview] = useState(false);

  function inviewOnChange (isVisible) {
    setIsInview(isVisible)
  }


  return (
    <VisibilitySensor partialVisibility={true} offset={{bottom:120}} active={!isInview}  onChange={inviewOnChange}>
      {({isVisible}) =>
        <section className={`section-2 ${isInview ? 'is_inview' : ''}`}>
          <div className="wrapper-large">
            <div className="sections">
              {
                sections.map((section) => {
                  return(
                    <>
                      <h2>{section.heading}</h2>
                      <div className="points">  
                      {
                        section.points.map((point) => {
                          return(
                              <article className="point">
                                <div className="icon">
                                  <img className="icon-img" src={point.icon.localFile.publicURL}  alt="= Icon" />
                                </div>
                                <h3 className="pointHeading">{point.heading}</h3>
                                <div className="text">{parse(point.text)}</div>
                              </article>
                          )
                        })
                      }
                      </div>
                    </>
                  )
                }) 
              }
            </div>
          </div>
        </section> 
      }
    </VisibilitySensor>  
  )
}


export default CareersSection2
